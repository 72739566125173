import React from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import App from './App';
import { SUBGRAPH_API } from './env';

const ApolloApp = () => {
  const client = new ApolloClient({
    uri: SUBGRAPH_API,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

export default ApolloApp;

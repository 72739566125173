const IS_PROD =
  !!process.env.REACT_APP_APP_ENV && process.env.REACT_APP_APP_ENV === 'prod';

// Chain ID
const TESTNET_CHAIN_ID = process.env.REACT_APP_TESTNET_CHAIN_ID
  ? process.env.REACT_APP_TESTNET_CHAIN_ID
  : '';
const PROD_CHAIN_ID = process.env.REACT_APP_PROD_CHAIN_ID
  ? process.env.REACT_APP_PROD_CHAIN_ID
  : '';
export const CHAIN_ID = IS_PROD ? PROD_CHAIN_ID : TESTNET_CHAIN_ID;

// Chain Name
const TESTNET_CHAIN_NAME = process.env.REACT_APP_TESTNET_CHAIN_NAME
  ? process.env.REACT_APP_TESTNET_CHAIN_NAME
  : '';
const PROD_CHAIN_NAME = process.env.REACT_APP_PROD_CHAIN_NAME
  ? process.env.REACT_APP_PROD_CHAIN_NAME
  : '';
export const CHAIN_NAME = IS_PROD ? PROD_CHAIN_NAME : TESTNET_CHAIN_NAME;

// Contract address
const REACT_APP_TESTNET_ADDRESS = process.env.REACT_APP_TESTNET_ADDRESS
  ? process.env.REACT_APP_TESTNET_ADDRESS
  : '';

const REACT_APP_PROD_ADDRESS = process.env.REACT_APP_PROD_ADDRESS
  ? process.env.REACT_APP_PROD_ADDRESS
  : '';

export const CONTRACT_ADDRESS = IS_PROD
  ? REACT_APP_PROD_ADDRESS
  : REACT_APP_TESTNET_ADDRESS;

// Subgraph API
const REACT_APP_TESTNET_SUBGRAPH_API = process.env
  .REACT_APP_TESTNET_SUBGRAPH_API
  ? process.env.REACT_APP_TESTNET_SUBGRAPH_API
  : '';

const REACT_APP_PROD_SUBGRAPH_API = process.env.REACT_APP_PROD_SUBGRAPH_API
  ? process.env.REACT_APP_PROD_SUBGRAPH_API
  : '';

export const SUBGRAPH_API = IS_PROD
  ? REACT_APP_PROD_SUBGRAPH_API
  : REACT_APP_TESTNET_SUBGRAPH_API;

// Images of the bears without a background
const TEST_BLANK_BG_BEAR_URL = process.env.REACT_APP_TEST_BLANK_BG_BEAR_URL
  ? process.env.REACT_APP_TEST_BLANK_BG_BEAR_URL
  : '';

const PROD_BLANK_BG_BEAR_URL = process.env.REACT_APP_PROD_BLANK_BG_BEAR_URL
  ? process.env.REACT_APP_PROD_BLANK_BG_BEAR_URL
  : '';

export const BLANK_BG_BEAR_URL = IS_PROD
  ? PROD_BLANK_BG_BEAR_URL
  : TEST_BLANK_BG_BEAR_URL;

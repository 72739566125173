import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { gql, useQuery } from '@apollo/client';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';

import './App.css';

import middleButtonImage from './assets/images/middle_button.png';
import leftButtonImage from './assets/images/arrow_left.png';
import rightButtonImage from './assets/images/arrow_right.png';

import middleButtonImagePressed from './assets/images/middle_button-pressed.png';
import leftButtonImagePressed from './assets/images/arrow_left-pressed.png';
import rightButtonImagePressed from './assets/images/arrow_right-pressed.png';

import curtains from './assets/images/curtains-padding.png';
import loadingBgImage from './assets/images/loading_bg.gif';
import loadingKoalaImage from './assets/images/loading_koala.gif';
import noBearImage from './assets/images/no_bear.png';

import { CHAIN_ID, CHAIN_NAME, BLANK_BG_BEAR_URL } from './env';
import { LoadingElement } from './components/utils';
import {
  connectMetamask,
  useNetworkAddress,
  getBearOwners,
  watchTransaction,
  initiateChangeBackgroundRequest,
} from './utils/blockchain';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GET_CHANGE_BACKGROUND_REQUESTS = gql`
  query {
    backgrounds(where: { isActive: true }) {
      id
      isActive
      recentUpdate {
        id
        locationUrl
      }
    }
  }
`;

interface BackgroundObject {
  name: string;
  image: string;
  isGif: boolean;
  isOriginal: boolean;
  metaUrl: string;
  timeDelay: number;
  collaboratorName: string;
  collaboratorLink: string;
  isCollaboration: boolean;
}

function App() {
  const { userWalletAddress, chainId } = useNetworkAddress();

  const [currentSelectedBearIndex, setCurrentSelectedBearIndex] = useState(-1);
  const [walletOwnersBears, setWalletOwnersBears] = useState<any[]>([]);

  const [currentSelectedBackgroundIndex, setCurrentSelectedBackgroundIndex] =
    useState(-1);
  const [availableBackgrounds, setAvailableBackgrounds] = useState<
    BackgroundObject[]
  >([]);
  const [currentSelectedBackground, setCurrentSelectedBackground] =
    useState<BackgroundObject | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBears, setIsLoadingBears] = useState(true);

  const [isBearsImageLoaded, setIsBearsImageLoaded] = useState(false);
  const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState('');
  const [updateButtonText, setUpdateButtonText] = useState('Update!');

  const [hasBears, setHasBears] = useState(false);
  const [hasBackgrounds, setHasBackgrounds] = useState(false);
  const [canUpdateState, setCanUpdateState] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<Color>('warning');

  const {
    loading: isLoadingBackgrounds,
    // error: errorBackgrounds,
    data: backgroundData,
  } = useQuery(GET_CHANGE_BACKGROUND_REQUESTS);

  const requestAndSetBackgroundMetaData = async (backgroundData: any) => {
    const backgroundSet: any[] = await Promise.all(
      backgroundData.backgrounds.map((currentBackgroundElement: any) =>
        axios.get(currentBackgroundElement.recentUpdate.locationUrl, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      )
    );

    if (Array.isArray(backgroundSet)) {
      setCurrentSelectedBackgroundIndex(0);
      const originalBackgrounds: BackgroundObject[] = [];
      const otherBackgrounds: BackgroundObject[] = [];

      backgroundSet.forEach((currentBackground) => {
        if ('data' in currentBackground && currentBackground.data) {
          const backgroundObject = {
            ...currentBackground.data,
            metaUrl: currentBackground.request.responseURL,
          };

          if (backgroundObject.isOriginal) {
            originalBackgrounds.push(backgroundObject);
          } else {
            otherBackgrounds.push(backgroundObject);
          }
        }
      });

      originalBackgrounds.sort((a, b) => ('' + a.name).localeCompare(b.name));
      otherBackgrounds.sort((a, b) => ('' + a.name).localeCompare(b.name));

      setAvailableBackgrounds([...otherBackgrounds, ...originalBackgrounds]);
    }
  };

  useEffect(() => {
    if (
      !isLoadingBackgrounds &&
      backgroundData &&
      'backgrounds' in backgroundData &&
      Array.isArray(backgroundData.backgrounds)
    ) {
      requestAndSetBackgroundMetaData(backgroundData);
    }
  }, [isLoadingBackgrounds, backgroundData]);

  const updateBearsListing = async (userWalletAddress: string) => {
    setIsLoadingBears(true);
    const ownersBearsResults = await getBearOwners(userWalletAddress);

    if (ownersBearsResults.result) {
      setCurrentSelectedBearIndex(0);
      const ownersBearListing = [...ownersBearsResults.transactionDetails];
      ownersBearListing.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

      setWalletOwnersBears(ownersBearListing);
    }

    setIsLoadingBears(false);
  };

  useEffect(() => {
    setIsLoading(false);
    if (userWalletAddress) {
      updateBearsListing(userWalletAddress);
    }
  }, [userWalletAddress, chainId]);

  useEffect(() => {
    setHasBears(
      !isLoadingBears &&
        !!walletOwnersBears &&
        walletOwnersBears.length > 0 &&
        currentSelectedBearIndex >= 0
    );
  }, [currentSelectedBearIndex, isLoadingBears, walletOwnersBears]);

  useEffect(() => {
    if (
      availableBackgrounds.length > 0 &&
      availableBackgrounds[currentSelectedBackgroundIndex]
    ) {
      setCurrentSelectedBackground(
        availableBackgrounds[currentSelectedBackgroundIndex]
      );
    }
  }, [availableBackgrounds, currentSelectedBackgroundIndex]);

  useEffect(() => {
    setHasBackgrounds(
      !isLoadingBackgrounds &&
        availableBackgrounds.length > 0 &&
        !!currentSelectedBackground
    );
  }, [availableBackgrounds, currentSelectedBackground, isLoadingBackgrounds]);

  useEffect(() => {
    setCanUpdateState(hasBears && hasBackgrounds);
  }, [hasBears, hasBackgrounds]);

  const handleUpdateClick = async () => {
    setIsLoading(true);
    setUpdateButtonText('Updating...');

    const selectedBear = walletOwnersBears[currentSelectedBearIndex];
    const selectedBackground =
      availableBackgrounds[currentSelectedBackgroundIndex];

    console.log('>>> currentSelectedBackgroundIndex');
    console.log(currentSelectedBackgroundIndex);
    console.log('>>> selectedBear');
    console.log(selectedBear);
    console.log('>>> selectedBackground');
    console.log(selectedBackground);

    setAlertType('warning');
    if (chainId !== CHAIN_ID) {
      setUpdateButtonText('Update!');
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
      setIsLoading(false);
    } else if (!userWalletAddress) {
      setUpdateButtonText('Update!');
      setAlertMessage('There needs to be a connected wallet.');
      setShowAlert(true);
      setIsLoading(false);
    } else {
      setLoadingMessage('Waiting for MetaMask approval...');

      const changeBackgroundRequestTransaction =
        await initiateChangeBackgroundRequest(
          selectedBear,
          selectedBackground.metaUrl
        );
      console.log(changeBackgroundRequestTransaction);
      setLoadingMessage('Waiting for block confirmations...');

      const { message, result, transactionDetails }: any =
        changeBackgroundRequestTransaction;
      console.log(transactionDetails);

      if (result) {
        // Pass transactiion to be watched
        const completeBackgroundChangeRequestCallback = () => {
          setUpdateButtonText('Update!');
          setIsLoading(false);
          setAlertType('success');
          setLoadingMessage('');
          setAlertMessage(
            `Your Drop Bear is currently being reconstructed on-chain! Wait 5 to 10 minutes then refresh your metadata on OpenSea!`
          );
          setShowAlert(true);
        };

        if (
          !!transactionDetails &&
          'hash' in transactionDetails &&
          !!transactionDetails.hash
        ) {
          watchTransaction(
            transactionDetails.hash,
            completeBackgroundChangeRequestCallback
          );
        }
      } else {
        // Show error message
        setUpdateButtonText('Update!');
        setAlertMessage(message);
        setShowAlert(true);
        setLoadingMessage('');
        setIsLoading(false);
      }
    }
  };

  const handleClickWallet = async () => {
    const { result, message } = await connectMetamask();

    if (!result) {
      setAlertType('warning');
    } else {
      setAlertType('success');
    }
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  // Set bear text
  let bearIdText = 'Loading Bears...';
  if (!isLoadingBears) {
    if (hasBears) {
      bearIdText = `Bear ID: ${walletOwnersBears[currentSelectedBearIndex]}`;
    } else {
      bearIdText = 'No owned DropBears';
    }
  }

  // Set background text
  let backgroundToggleText = 'Loading Backgrounds...';
  if (!isLoadingBackgrounds) {
    if (hasBackgrounds && !!currentSelectedBackground) {
      backgroundToggleText = `Background: ${currentSelectedBackground.name}`;
    } else {
      backgroundToggleText = 'No backgrounds found';
    }
  }

  const handleIncrementCurrentBearId = () => {
    if (!isLoading && hasBears) {
      setIsBearsImageLoaded(false);
      if (currentSelectedBearIndex < walletOwnersBears.length - 1) {
        // Just increment up one
        setCurrentSelectedBearIndex(currentSelectedBearIndex + 1);
      } else {
        // Roll over to the beginning of the array of bears
        setCurrentSelectedBearIndex(0);
      }
    }
  };

  const handleDecrementCurrentBearId = () => {
    if (!isLoading && hasBears) {
      setIsBearsImageLoaded(false);
      if (currentSelectedBearIndex > 0) {
        // Just decrement up one
        setCurrentSelectedBearIndex(currentSelectedBearIndex - 1);
      } else {
        // Loop to the end of the array of bears
        setCurrentSelectedBearIndex(walletOwnersBears.length - 1);
      }
    }
  };

  const handleIncrementCurrentBackgroundIndex = () => {
    if (!isLoading && hasBackgrounds) {
      setIsBackgroundImageLoaded(false);
      if (currentSelectedBackgroundIndex < availableBackgrounds.length - 1) {
        // Just increment up one
        setCurrentSelectedBackgroundIndex(currentSelectedBackgroundIndex + 1);
      } else {
        // Roll over to the beginning of the array of backgrounds
        setCurrentSelectedBackgroundIndex(0);
      }
    }
  };

  const handleDecrementCurrentBackgroundIndex = () => {
    if (!isLoading && hasBackgrounds) {
      setIsBackgroundImageLoaded(false);
      if (currentSelectedBackgroundIndex > 0) {
        // Just decrement up one
        setCurrentSelectedBackgroundIndex(currentSelectedBackgroundIndex - 1);
      } else {
        // Loop to the end of the array of backgrounds
        setCurrentSelectedBackgroundIndex(availableBackgrounds.length - 1);
      }
    }
  };

  let bearImage = noBearImage;

  if (!isBearsImageLoaded) {
    bearImage = loadingKoalaImage;
  } else if (hasBears) {
    bearImage = `${BLANK_BG_BEAR_URL}${walletOwnersBears[currentSelectedBearIndex]}.png`;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <div className="App">
        <header className="App-header">
          <div className="PhotoboothTitle-section">
            <h1 className="Title">Drop Bears Photobooth</h1>
            <p
              className={`WalletText TextOutline${
                !!userWalletAddress ? ' WalletText-reduced' : ''
              }`}
            >
              {!!userWalletAddress ? (
                <>
                  <span>Wallet:</span> {userWalletAddress}
                </>
              ) : (
                'No wallet found'
              )}
            </p>
            {!!userWalletAddress ? (
              <>
                <div className="LandingImage-Frame">
                  <img
                    src={
                      !!currentSelectedBackground && isBackgroundImageLoaded
                        ? currentSelectedBackground.image
                        : loadingBgImage
                    }
                    className="Curtain-holder Curtain-NestedElement"
                    alt="Background"
                    onLoad={() => setIsBackgroundImageLoaded(true)}
                  />
                  <img
                    src={bearImage}
                    className="Curtain-holder Curtain-NestedElement"
                    alt="Bear"
                    onLoad={() => setIsBearsImageLoaded(true)}
                  />
                  <img
                    src={curtains}
                    className="Curtain-holder"
                    alt="Curtain"
                  />
                </div>

                <div className="Selector-ToggleSection">
                  <div className="Selector-section">
                    <Button
                      disabled={!userWalletAddress || isLoading || !hasBears}
                      className="SelectBackgroundButton"
                      variant="contained"
                      onClick={handleDecrementCurrentBearId}
                      size="large"
                    >
                      <img
                        src={
                          !hasBears || isLoading
                            ? leftButtonImagePressed
                            : leftButtonImage
                        }
                        className="MiddleButtonImage"
                        alt="logo"
                      />
                    </Button>

                    <h2 className="BearIdTitle TextOutline">{bearIdText}</h2>

                    <Button
                      disabled={!userWalletAddress || isLoading || !hasBears}
                      className="SelectBackgroundButton"
                      variant="contained"
                      onClick={handleIncrementCurrentBearId}
                      size="large"
                    >
                      <img
                        src={
                          !hasBears || isLoading
                            ? rightButtonImagePressed
                            : rightButtonImage
                        }
                        className="MiddleButtonImage"
                        alt="logo"
                      />
                    </Button>
                  </div>
                </div>

                <div className="Selector-ToggleSection">
                  <div className="Selector-section">
                    <Button
                      disabled={
                        !userWalletAddress || isLoading || !hasBackgrounds
                      }
                      className="SelectBackgroundButton"
                      variant="contained"
                      onClick={handleDecrementCurrentBackgroundIndex}
                      size="large"
                    >
                      <img
                        src={
                          !hasBackgrounds || isLoading
                            ? leftButtonImagePressed
                            : leftButtonImage
                        }
                        className="MiddleButtonImage"
                        alt="logo"
                      />
                    </Button>

                    <div>
                      <h2 className="BackgroundTitleSection TextOutline">
                        {backgroundToggleText}
                      </h2>

                      {currentSelectedBackground &&
                        currentSelectedBackground.isCollaboration && (
                          <p className="ChooseBackgroundTitleSection TextOutline">
                            <span className="ChooseBackgroundTitle">
                              Designed by:{' '}
                            </span>
                            <a
                              className="ChooseBackgroundLink"
                              href={currentSelectedBackground.collaboratorLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="ChooseBackgroundTitle">
                                {currentSelectedBackground.collaboratorName}
                              </span>
                            </a>
                          </p>
                        )}

                      <p className="ChooseBackgroundTitleSection TextOutline">
                        <span className="ChooseBackgroundTitle">
                          {hasBackgrounds &&
                            `${currentSelectedBackgroundIndex + 1} out of ${
                              availableBackgrounds.length
                            }`}
                        </span>
                      </p>
                    </div>

                    <Button
                      disabled={
                        !userWalletAddress || isLoading || !hasBackgrounds
                      }
                      className="SelectBackgroundButton"
                      variant="contained"
                      onClick={handleIncrementCurrentBackgroundIndex}
                      size="large"
                    >
                      <img
                        src={
                          !hasBackgrounds || isLoading
                            ? rightButtonImagePressed
                            : rightButtonImage
                        }
                        className="MiddleButtonImage"
                        alt="logo"
                      />
                    </Button>
                  </div>
                </div>

                <Button
                  disabled={!userWalletAddress || isLoading || !canUpdateState}
                  className="SelectBackgroundButton"
                  variant="contained"
                  onClick={handleUpdateClick}
                  size="large"
                >
                  <img
                    src={
                      isLoading || !canUpdateState
                        ? middleButtonImagePressed
                        : middleButtonImage
                    }
                    className="MiddleButtonImage"
                    alt="logo"
                  />
                  <span className="MiddleButtonText">
                    <span>{updateButtonText}</span>
                    {isLoading && <LoadingElement color="white" />}
                  </span>
                </Button>
                {!!loadingMessage && (
                  <p className="LoadingText">{loadingMessage}</p>
                )}
              </>
            ) : (
              <>
                <h3 className="ConnectMetamaskTitle TextOutline">
                  Connect MetaMask to get started
                </h3>

                <div>
                  <Button
                    className="ConnectMetamaskButton"
                    variant="contained"
                    size="large"
                    onClick={handleClickWallet}
                    disabled={isLoading}
                  >
                    {isLoading && <LoadingElement />} Connect MetaMask
                  </Button>
                </div>
              </>
            )}
          </div>
        </header>

        <div className="Footer-container">
          <div className="Footer-section">
            <img
              alt=""
              src={
                'https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611f1d4156f2c3a0cb80c2b5_Drop%20Bears%20Io%20PTY%20LTD.png'
              }
              className="image-18"
            />
          </div>

          <div className="Footer-section Text-center">
            <img
              alt=""
              src={
                'https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611f1cdef6dbed6d50b2cf2a_white_dropbears_M.png'
              }
              className="image-dropbears-logo"
            />
          </div>

          <div className="Footer-section Text-right">
            <a
              rel="noreferrer"
              href="https://twitter.com/DropBearsio"
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                alt=""
                src={
                  'https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611c45a1d62fd14b04becc11_gorjeo%20(1).svg'
                }
                className="social-icon"
              />
            </a>
            <a
              rel="noreferrer"
              href="https://discord.gg/PeC8VUNFgp"
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                alt=""
                src={
                  'https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/612439a48640ce6a5d8cb623_Discord-Logo-White.svg'
                }
                width="63"
                className="social-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
